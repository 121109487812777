(function() {
	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if(entry.isIntersecting) {
				entry.target.run();
			}
		})
	}, {
		threshold: 0.75
	});

	$('.skill').each(function() {
		const el = this;
		const self = $(this);
		const mount = self.find('.skill__bar__mount');
		const bar = self.find('.skill__bar');

		const counterup = mount.data('counterup')
		const suffix = mount.data('suffix') || ''
		const preffix = mount.data('preffix') || ''

		mount.text(`${preffix}0${suffix}`);
		observer.observe(this);

		this.run = function() {
			bar.addClass('active');
			CounterUP({
				mount,
				counterup,
				suffix,
				preffix
			});
		}
	});
})();

function CounterUP({
	mount,
	counterup,
	suffix,
	preffix
}) {
	let range = counterup
	let duration = 2000
	let inicio = 1
	let minTime = 50
	let stepTime = Math.abs(Math.floor(duration / range))
	let startTime = new Date().getTime()

	stepTime = Math.max(stepTime, minTime)

	let endTime = startTime + duration
	let interval = setInterval(contador, stepTime)

	function contador() {
		let now = new Date().getTime()
		let remanescente = Math.max((endTime - now) / duration, 0)
		let valor = Math.round(range - (remanescente * range))

		mount.text(`${preffix}${valor}${suffix}`)

		if (valor === range) {
			clearInterval(interval);
		}
	}
}
