(function() {
	let body = document.body;
	let contato = document.querySelector('#contato');

	if(contato) {
		contatoOffset();

		function contatoOffset() {
			body.style.setProperty('--contato', (contato.offsetHeight / -2)+'px')

			requestAnimationFrame(contatoOffset)
		}
	}
})();
