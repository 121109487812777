(function() {
	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if(entry.isIntersecting) {
				let bg = entry.target.dataset.lazybg || '';

				entry.target.removeAttribute('data-lazybg');
				entry.target.style.setProperty('--lazybg', `url(${JSON.stringify(bg)})`);
				observer.unobserve(entry.target);
			}
		})
	});

	document.querySelectorAll('[data-lazybg]').forEach(el=> observer.observe(el));
})();
