(function() {
	const topo = document.querySelector('.topo');
	const blurEls = document.querySelectorAll('.mobile__controls, .wrapper, .rodape');
	const search = document.querySelector('.mobile__search');
	const menu = document.querySelector('.topo__main');
	let animationTiming = {
		duration: 600,
		easing: 'linear',
		fill: 'forwards'
	};

	mobileToggle({
		btn: document.querySelector('[data-toggle="search"]'),
		classe: 'search--shown',
		target: search
	});

	mobileToggle({
		btn: document.querySelector('[data-toggle="menu"]'),
		classe: 'topo__main--shown',
		target: menu
	});

	function mobileToggle({btn, classe, target}) {
		btn.addEventListener('click', function() {

			let bck = document.createElement('div');

			btn.setAttribute('aria-expanded', true);
			bck.classList.add('backdrop');
			target.classList.add(classe);
			topo.appendChild(bck);

			blurEls.forEach(el=> {
				el.animate([
					{filter: 'blur(0px)'},
					{filter: 'blur(4px)'}
				], animationTiming);
			});

			bck.addEventListener('click', function() {
				target.classList.remove(classe);
				btn.setAttribute('aria-expanded', false);
				blurEls.forEach(el => {
					el.animate([
						{ filter: 'blur(4px)' },
						{ filter: 'blur(0px)' }
					], animationTiming);
				});
				bck.classList.add('hide');
				bck.addEventListener('animationend', function() {
					topo.removeChild(bck);
				})
			});
		})
	}
})();
