(function() {
	let slider = $('#misaoVisao');

	if (slider.length > 0) {
		let indicators = slider.find('.nav__missao__visao');

		setThumb();

		slider.on('slide.bs.carousel', function(e) {
			let to = e.to;

			console.log(to);

			indicators.find('li').each(function() {
				if($(this).data('slide-to') === to) {
					$(this).addClass('active');
				}else {
					$(this).removeClass('active')
				}
			});

			setThumb();
		})

		function setThumb() {
			let indicatorActive = indicators.find('.active');

			indicators.css({
				'--w': indicatorActive.outerWidth() + 'px',
				'--l': indicatorActive.position().left+'px'
			});
		}
	}
})();
